.hero-image {
    position: relative;
    height: 500px;
    overflow: hidden;
    background: url('../../images/cover.png') no-repeat center;
    background-size: cover;
}

.hero-caption-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    text-align: center;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}

.hero-caption-container h3 {
    font-size: 35px;
    letter-spacing: 0.1em;
}

.hero-caption-container h3 span {
    display: inline-block;
}

.hero-caption-container a.btn-contact-us {
    background: #fff;
    color: #5E85C4;
    text-decoration: none;
}

.hero-caption-container a.btn-contact-us {
    padding: 2px auto;
    font-size: 1rem;
    background: #fff;
    color: #5E85C4;
    text-decoration: none;
    transition: all 0.2s ease-out;
}

.hero-caption-container a.btn-contact-us:hover {
    padding: 2px auto;
    font-size: 1rem;
    background: #5E85C4;
    color: #fff;
}