.h-our-services__inner .h-our-services__inner__item {
    position: relative;
    padding: 15px;
    color: #c0c0c0;
    border: 1px solid #c0c0c0;
}

.h-our-services__inner .h-our-services__inner__item--first {
    color: #c0c0c0;
    opacity: 1;
    border: none;
    line-height: 1.8rem;
}

.h-our-services__inner .h-our-services__inner__item--first h4:after {
    content: '';
    margin: .6rem 0;
    display: block;
    width: 35%;
    border: 2px solid #5E85C4;
}

.h-our-services__inner .h-our-services__inner__item:not(:first-child) {
    cursor: pointer;
    opacity: .4;
    transition: all 0.3s ease-out;
}

.h-our-services__inner .h-our-services__inner__item:not(:first-child):hover{
    opacity: 1;
}

.h-our-services__inner .h-our-services__inner__item:not(:first-child).active {
    opacity: 1;
}