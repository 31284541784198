.about-us__line {
    padding: 0 15%;
}

.about-us__line li {
    list-style-type: none;
    float: left;
    width: 50%;
    position: relative;
    text-align: center;
}

.about-us__line li:before {
    content: '';
    width: 15px;
    height: 15px;
    line-height: 15px;
    border: 1px solid #c0c0c0;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.about-us__line li:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 3.5px;
    background-color: #c0c0c0;
    top: 6.5px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out;
}

.about-us__line li:first-child:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 3.5px;
    background-color: #c0c0c0;
    border: none;
    top: 6.5px;
    left: 50%;
    z-index: -1;
}

.about-us__line li.active {
    color: #5E85C4;
    font-weight: 900;
}

.about-us__line li.active:first-child:before, .about-us__line li.active:first-child:after, .about-us__line li.active:nth-child(2):before, .about-us__line li.active:nth-child(2):after {
    border-color: #5E85C4;
    background-color: #5E85C4;
}