.services-card__item {
    position: relative;
    padding: 15%;
    text-align: center;
    background: #fff;
    color: #c0c0c0;
    transition: all 0.2s ease-out;
    cursor: pointer;
}



.services-card__item:hover {
    background: #5E85C4;
    color: #fff;
}

.services-card__item.active {
    background: #5E85C4;
    color: #fff;
}

.services-card__item__image {
    width: 50px;
    height: 50px;
}

.services-card__item.active-border:not(:last-child)::after {
    position: absolute;
    content: '';
    top: 15%;
    right: 0px;
    height: 70%;
    border-right: 3px solid #AFAFAF;
}

