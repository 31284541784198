.services-card-container {
    margin-top: -4%;
}

.h-about-us-container {
    margin: 0 17%;
}

.h-about-us-container h3 {
    text-align: center;
    color: #5E85C4;
}



.h-about-us-content {

    width: 100%;
    height: auto;
    padding-top: 50px;
}

.h-about-us-content__inner {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 10px;
    width: 100%;
}

.h-about-us-content__inner .h-about-us-content__inner__item {
    line-height: 1.8rem;
}

.portfolio-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 100%;
}

.portfolio-section .portfolio-section__item {
    position: relative;
    color: #fff;
    padding: 40% 25%;
    /* padding: 30%; */
    height: 650px;
    text-align: center;
    align-items: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.portfolio-section .portfolio-section__item .portfolio-section__item__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-out;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.portfolio-section .portfolio-section__item .portfolio-section__item__image:hover {
    transform: scale(1.1);
    transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.portfolio-section .portfolio-section__item a {
    /* position: absolute; */
    width: 100%;
    display: block;
    top: 50%;
    color: #fff;
    opacity: 1;
    text-decoration: none;
    margin: auto 0;
    padding: 1%;
    border: 1px solid #fff;
    transition: all 0.2s ease-out;
}

.portfolio-section .portfolio-section__item__button {
    position: relative;
    width: 100%;
}

.portfolio-section .portfolio-section__item__button a:hover {
    border-color: #5E85C4;
    background-color: #5E85C4;
   
}

.portfolio-overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
}



@media screen and (max-width: 1200px) {
    .portfolio-section {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}


@media screen and (max-width: 600px) {
    .portfolio-section {
        grid-template-columns: repeat(auto-fit, 1fr);
    }
}


@media screen and (min-width: 1200px) {
    .h-about-us-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 5%;
    }
}

@media screen and (max-width: 1199px) {
    .h-about-us-content__image {
        object-fit: 'cover';
        float: left;
        width: 50%;
        margin-right: 20px;
     }

     .h-about-us-content__text {
         text-align: justify;
     }
}


@media screen and (max-width: 700px) {
    .h-about-us-content__image {
        object-fit: 'cover';
        width: 100%;
        margin-bottom: 20px;
     }

     .h-about-us-content__text {
         text-align: left;
     }
}



