.h-our-services {
    padding: 3% 17%;
    max-width: 100%;
    background-color: #242529;
}

.h-our-services__inner {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 40px;
}


@media screen and (max-width: 600px) {
    .h-our-services__inner {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    }
}