.navbar-container {
    /* display: flex; */
    background: #fff;
    /* height: 10%;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem; */
    font-family: 'MarkBlack';
    text-transform: uppercase;
}

.logo {
    width: auto;
    height: 50%;
}

.nav-phone {
    color: #c0c0c0;
}

.nav-link-container a{
margin: 0 0.8rem;
}

.custom-nav-link-item {
    color: #c0c0c0 !important;
    text-decoration: none;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.custom-nav-link-item:hover {
    background-color: #FAFAFA;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color: #727273 !important;
}