@font-face {
  font-family: 'Quark';
  /* src: local('Quark-Light'), url('./fonts/Quark-Light.otf') format('otf'); */
  src: url('./fonts/Quark-Light.otf') format('otf'), /* Super Modern Browsers */
       url('./fonts/Quark-Bold.otf') format('otf') /* Pretty Modern Browsers */
}

/* @font-face {
  font-family: 'Quark-Bold';
  src: local('Quark-Bold'), url('./fonts/Quark-Bold.otf') format('otf');
}
*/

 @font-face {
  font-family: 'MarkBlack';
  src: local('MarkBlack'), url('./fonts/MarkBlack.otf') format('otf');
} 

html, body {
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quark', 'MarkBlack', sans-serif;

  /* font-family: 'Quark-Light' !important; */
}